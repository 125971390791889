import Vue from 'vue';
import { init } from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

const sentryDsn = process.env.VUE_APP_SENTRY_DSN;

if (sentryDsn) {
  init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new VueIntegration({ Vue })],
    environment: process.env.VUE_APP_ENV,
  });
}
