






import { defineComponent } from '@vue/composition-api'
import MobileNav from '@/components/organisms/MobileNav/MobileNav.vue'

export default defineComponent({
  name: 'TopBar',
  components: { MobileNav }
})
