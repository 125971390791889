import * as LD from 'launchdarkly-js-client-sdk';
import { User } from '@/models';

export const FeatureFlagServiceToken = Symbol('FeatureFlagService token');

export class FeatureFlagService {

  constructor(private apiKey?: string) {}

  private client: LD.LDClient | null = null;

  async init(): Promise<void> {
    if (!this.apiKey) return Promise.resolve();

    this.client = LD.initialize(this.apiKey, { anonymous: true });

    return new Promise((resolve) => this.client?.on('ready', resolve));
  }

  async identify(user: User): Promise<void> {
    if (!this.client) return Promise.resolve();

    return new Promise((resolve) => {
      this.client?.identify({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.username,
        key: user.id,
      }, user.id, () => resolve());
    });
  }

  isActive(flag: string): boolean {
    return this.client?.variation(flag, false) ?? false;
  }

}
