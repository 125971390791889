export enum Locale {
  // DE = 'de',
  EN = 'en-GB',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
  // LT = 'lt',
  // LV = 'lv',
  // NL = 'nl',
  // PL = 'pl',
  // PT = 'pt',
  // RU = 'ru',
}
