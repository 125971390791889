import { transformKeysToCamelCase } from '@/utils/casing'
import { HttpClientForProxy } from '../httpClient.type'

export const FrontServiceToken = Symbol('FrontService token')

interface FrontMessageEntity {
  id: string
  text: string
  subject: string
}

export class FrontService {

  constructor(
    private httpClientForProxy: HttpClientForProxy,
    private pageSize: number = 10,
  ) {}

  async getMessagesFromConversation(conversationId: string) {
    let pageToken: string | null = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const results: any[] = [];

    do {
      const queryString = [
        `limit=${this.pageSize}`,
        ...(pageToken ? [`page_token=${pageToken}`] : []),
      ].join('&');

      // eslint-disable-next-line no-await-in-loop
      const response = await this.httpClientForProxy.get(
        `${process.env.VUE_APP_API_PROXY_URL}/get-messages/${conversationId}?${queryString}`,
        {
          headers: {
            'authorization-host': process.env.VUE_APP_API_URL
          }
        }
      )

      results.push(response.data._results);

      if (!response.data._pagination.next) break;

      pageToken = (new URL(response.data._pagination.next)).searchParams.get('page_token');

    } while (pageToken);

    return transformKeysToCamelCase<FrontMessageEntity[]>(results)
  }

}
