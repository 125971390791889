import { RouteConfig } from 'vue-router'
import names from '@/router/routes/names'

interface RouteMetadata {
  public?: boolean
}

type AppRouteConfig = RouteConfig & { meta?: RouteMetadata }

export const homeRoute: AppRouteConfig = {
  path: '/',
  name: names.searchOrders,
  component: () =>
    import(
      /* webpackChunkName: "search-orders" */ '@/components/pages/SearchOrders/SearchOrders.vue'
    )
}

export const searchOrders: AppRouteConfig = {
  path: '/search-orders',
  name: names.searchOrders,
  component: () =>
    import(
      /* webpackChunkName: "search-orders" */ '@/components/pages/SearchOrders/SearchOrders.vue'
    )
}

export const userNotEnabledApi: AppRouteConfig = {
  path: '/enable-api',
  name: names.userNotEnabledApi,
  component: () =>
    import(
      /* webpackChunkName: "user-not-enabled-api" */ '@/components/pages/UserNotEnabledApi/UserNotEnabledApi.vue'
    )
}

export const orderDetails: AppRouteConfig = {
  path: '/order-details',
  name: names.orderDetails,
  component: () =>
    import(
      /* webpackChunkName: "order-details" */ '@/components/pages/OrderDetails/OrderDetails.vue'
    )
}

export const logout: AppRouteConfig = {
  path: '/logout',
  name: names.logout,
  component: () =>
    import(/* webpackChunkName: "logout" */ '@/components/pages/Logout/Logout.vue')
}

export const loginRoute: AppRouteConfig = {
  path: '/login',
  component: () =>
    import(/* webpackChunkName: "login" */ '@/components/pages/Login/Login.vue'),
  meta: { public: true },
  children: [
    {
      path: '/login',
      name: names.login,
      component: () =>
        import(
          /* webpackChunkName: "login-form" */ '@/components/organisms/LoginForm/LoginForm.vue'
        ),
      meta: { public: true }
    },
    {
      path: '/forgot-password',
      name: names.forgotPassword,
      component: () =>
        import(
          /* webpackChunkName: "forgot-password" */ '@/components/organisms/ForgotPasswordForm/ForgotPasswordForm.vue'
        ),
      meta: { public: true },
      props: (route) => ({
        fromInvalidResetToken: route.query.fromInvalidResetToken === 'true'
      })
    },
    {
      path: '/reset-password',
      name: names.resetPassword,
      component: () =>
        import(
          /* webpackChunkName: "reset-password" */ '@/components/organisms/ResetPasswordForm/ResetPasswordForm.vue'
        ),
      meta: { public: true }
    }
  ]
}

export default [
  homeRoute,
  loginRoute,
  searchOrders,
  orderDetails,
  logout,
  userNotEnabledApi
]
