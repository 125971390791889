import mitt from 'mitt';

const eventBus = mitt();

export enum AppEvent {
  REDIRECT_TO_LOGIN = 'redirect_to_login'
}

export const bus = {
  emit: (event: AppEvent, payload?) => eventBus.emit(event, payload),
  on: (event: AppEvent, fn) => eventBus.on(event, fn),
};
