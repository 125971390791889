import { computed, ref } from '@vue/composition-api';

const activeModalIds = ref<string[]>([]);

export default (id: string) => {
  const show = () => activeModalIds.value.push(id);
  const hide = () => activeModalIds.value = activeModalIds.value.filter((i) => i !== id);
  const shown = computed<boolean>(() => activeModalIds.value.includes(id));

  return {
    show,
    hide,
    shown,
  };
};
