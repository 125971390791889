








import { defineComponent } from '@vue/composition-api'
import AppLayout from '@/components/organisms/AppLayout/AppLayout.vue'
import TopBar from '@/components/organisms/TopBar/TopBar.vue'
import MobileNav from '@/components/organisms/MobileNav/MobileNav.vue'
import useUser from '@/hooks/useUser'
import useFeatureFlags from '@/hooks/feature-flags/useFeatureFlags'
import useLoader from '@/hooks/useLoader'
import OrderLoading from '@/components/molecules/OrderLoading/OrderLoading.vue'

export default defineComponent({
  name: 'App',
  components: {
    AppLayout,
    TopBar,
    MobileNav,
    OrderLoading
  },
  setup(_, { root: { $router } }) {
    const { isLoading } = useLoader()
    const { isAuthenticated } = useUser($router)
    const { isActive } = useFeatureFlags()

    const activeNotifications = isAuthenticated && isActive('notifications')

    return {
      isAuthenticated,
      activeNotifications,
      isLoading
    }
  }
})
