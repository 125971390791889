import { User } from '@/models'

import { HttpClient } from '../httpClient.type'

export const UserServiceToken = Symbol('UserService token')

export enum UserServiceError {
  FORBIDDEN_USER = 'User is not a customer'
}

export default class UserService {

  static companyApi = '/ordering-customer/queries/get-company-details'
  static userApi = '/accounts/users/me'

  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async getUser(): Promise<User> {
    try {
      const { data: userInfo } = await this.httpClient.get('/accounts/users/me')

      return { ...userInfo }
    } catch (err) {
      return Promise.reject(err)
    }
  }

}
