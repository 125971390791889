import useConfirmationModalState, {
  ConfirmationState,
} from '@/hooks/confirmation-modal/useConfirmationModalState';
import useModal from '@/hooks/modal/useModal';

export const confirmationModalId = 'confirmationModalId';

export default (state: Partial<ConfirmationState>) => {
  const { updateState } = useConfirmationModalState();
  const { show } = useModal(confirmationModalId);

  return () => {
    updateState(state);

    show();
  };
};
