import {
  HttpClientToken,
  httpClient,
  httpClientForProxy,
  HttpClientForProxyToken
} from '@/services/httpClient'
import UserService, { UserServiceToken } from '@/services/User/UserService'
import AnalyticsService, {
  AnalyticsServiceToken
} from '@/services/Analytics/AnalyticsService'
import AuthenticationService, {
  AuthenticationServiceToken
} from '@/services/Authentication/AuthenticationService'
import OrderService, { OrderServiceToken } from '@/services/OrderService/OrderService'
import {
  FeatureFlagService,
  FeatureFlagServiceToken
} from '@/services/FeatureFlag/FeatureFlagService'

import AlertingService, {
  AlertingServiceToken
} from '@/services/Alerting/AlertingService'

export const analyticsService = new AnalyticsService()

const alertingService = new AlertingService()
const authenticationService = new AuthenticationService(httpClient)
const userService = new UserService(httpClient)
const orderService = new OrderService(httpClient, analyticsService)

export const featureFlagService = new FeatureFlagService(
  process.env.VUE_APP_LAUNCH_DARKLY_ID
)

export default {
  [AlertingServiceToken]: alertingService,
  [UserServiceToken]: userService,
  [AnalyticsServiceToken]: analyticsService,
  [OrderServiceToken]: orderService,
  [AuthenticationServiceToken]: authenticationService,
  [HttpClientToken]: httpClient,
  [HttpClientForProxyToken]: httpClientForProxy,
  [FeatureFlagServiceToken]: featureFlagService
}
