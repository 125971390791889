
















import { defineComponent } from '@vue/composition-api'
import useUser from '@/hooks/useUser'
import ConfirmationModal from '@/components/organisms/ConfirmationModal/ConfirmationModal.vue'

export default defineComponent({
  name: 'AppLayout',
  components: {
    ConfirmationModal
  },
  setup(props, { root: { $router } }) {
    const { isAuthenticated } = useUser($router)

    return {
      isAuthenticated
    }
  }
})
