













import { defineComponent } from '@vue/composition-api';

const typeValidator = ['primary'];

export default defineComponent({
  name: 'SvgIcon',
  props: {
    icon: { type: String, required: true },
    type: {
      type: String,
      default: 'primary',
      validator: (prop: string) => typeValidator.includes(prop),
    },
  },
});
