export default {
  home: 'Home',
  login: 'Login',
  success: 'Success',
  resetPassword: 'ResetPassword',
  forgotPassword: 'ForgotPassword',
  searchOrders: 'SearchOrders',
  orderDetails: 'OrderDetails',
  logout: 'Logout',
  userNotEnabledApi: 'UserNotEnabledApi'
}
