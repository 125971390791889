import VueRouter from 'vue-router'
import names from '@/router/routes/names'
import { computed, ref } from '@vue/composition-api'
import { User } from '@/models'
import AuthenticationService from '@/services/Authentication/AuthenticationService'
import Cookies from 'js-cookie'

export const user = ref<User | null>(null)

export default ($router: VueRouter) => {
  const logout = async () => {
    Cookies.remove(AuthenticationService.userTokenCookieName, {
      sameSite: 'None',
      secure: true
    })
    user.value = null
    await $router.push({ name: names.login })
  }

  return {
    user,
    logout,
    isAuthenticated: computed<boolean>(() => !!user.value)
  }
}
