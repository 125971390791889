import { OrderOverview } from '@/models/order.model'
import events from '@/notifications/events'
import { transformKeysToCamelCase } from '@/utils/casing'
import { ICacheLike } from 'axios-extensions'
import qs from 'qs'
import AnalyticsService from '../Analytics/AnalyticsService'
import { HttpClient } from '../httpClient.type'

declare module 'axios' {
  interface AxiosRequestConfig {
    // if your cacheFlag was setting to 'useCache'
    useCache?: boolean | ICacheLike<any>
  }
}

export const OrderServiceToken = Symbol('OrderService token')
interface GetOrderResponseEntity {
  reference: string
  orders: OrderOverview[]
}

export default class OrderService {

  static ordersApi = '/order-query/search-by-references'

  constructor(
    private httpClient: HttpClient,
    private analyticsService: AnalyticsService
  ) {}

  async getOrders(orderIds: Array<string>, useCache = true) {
    const requestStart = performance.now()
    // TODO : transform this method to GET so we can benefit of the axios cache
    const response = await this.httpClient.get(
      `${OrderService.ordersApi
      }?${
        qs.stringify(
          {
            references: orderIds,
            limit: 5
          },
          { arrayFormat: 'brackets' }
        )}`,
      { useCache }
    )
    const requestEnd = performance.now()
    this.analyticsService?.trackEvent(events.SEARCH_ELAPSED_TIME, {
      orderIds,
      ordersCount: response.data.map(result => result.orders.length).reduce((a, b) => a + b, 0),
      elapsedTime: Math.ceil(requestEnd - requestStart)
    })
    return transformKeysToCamelCase<GetOrderResponseEntity[]>(response.data)
  }

}
