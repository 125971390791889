import useLocaleStorage from '@/hooks/useLocaleStorage';
import VueI18n from 'vue-i18n';
import { computed } from '@vue/composition-api';
import { Locale } from '@/models/locales.model';

const lang = useLocaleStorage<string>('ui_lang', Locale.EN);

export default (i18n: VueI18n) => {
  i18n.locale = lang.value;

  const availableLocales = computed(() => i18n.availableLocales.map((locale) => ({
    id: locale,
    label: i18n.t(`locale.${locale}`),
  })));

  const changeLocale = (locale: string) => {
    lang.value = locale;
    i18n.locale = locale;
  };

  return {
    lang,
    availableLocales,
    changeLocale,
  };
};
