










import { computed, defineComponent } from '@vue/composition-api';
import { slideOriginValidator } from '@/components/atoms/Transitions/transition-utils';

export default defineComponent({
  name: 'TransitionSlide',
  props: {
    origin: {
      type: String,
      default: 'left',
      validator: slideOriginValidator,
    },
    appear: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: {origin: string}) {
    const transitionName = computed<string>(() => `transition-slide-from-${props.origin}`);

    return {
      transitionName,
    };
  },
});
