





















import { computed, defineComponent } from '@vue/composition-api'
import Title from '@/components/atoms/Title/Title.vue'
import useModal from '@/hooks/modal/useModal'
import { confirmationModalId } from '@/hooks/confirmation-modal/useConfirmationModal'
import useConfirmationModalState from '@/hooks/confirmation-modal/useConfirmationModalState'
import Modal from '@/components/molecules/Modal/Modal.vue'
import ModalActions from '@/components/molecules/ModalActions/ModalActions.vue'

export default defineComponent({
  name: 'ConfirmationModal',

  components: {
    Modal,
    Title,
    ModalActions
  },

  setup() {
    const { shown, hide } = useModal(confirmationModalId)
    const {
      title,
      subtitle,
      confirmLabel,
      cancelLabel,
      onConfirm
    } = useConfirmationModalState()
    const displayConfirm = computed<boolean>(() => !!onConfirm.value)

    const handleConfirm = async () => {
      try {
        if (onConfirm.value) {
          await onConfirm.value()
        }

        hide()
      } catch {
        //
      }
    }

    return {
      shown,
      hide,

      title,
      subtitle,
      confirmLabel,
      cancelLabel,
      handleConfirm,
      displayConfirm
    }
  }
})
