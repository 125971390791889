import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Locale } from '@/models/locales.model';
// import de from './locales/de.json';
import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
// import lt from './locales/lt.json';
// import lv from './locales/lv.json';
// import nl from './locales/nl.json';
// import pl from './locales/pl.json';
// import pt from './locales/pt.json';
// import ru from './locales/ru.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || Locale.EN,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || Locale.EN,
  messages: {
    // [Locale.DE]: de,
    [Locale.EN]: en,
    [Locale.ES]: es,
    [Locale.FR]: fr,
    [Locale.IT]: it,
    // [Locale.LT]: lt,
    // [Locale.LV]: lv,
    // [Locale.NL]: nl,
    // [Locale.PL]: pl,
    // [Locale.PT]: pt,
    // [Locale.RU]: ru,
  },
});
