import axios, { AxiosError } from 'axios'
import Cookies from 'js-cookie'
import AuthenticationService from '@/services/Authentication/AuthenticationService'
import { stringify } from 'querystring'
import { AppEvent, bus } from '@/events'
import { cacheAdapterEnhancer } from 'axios-extensions'
import { getAuthorizationHeaderWithParams, getInstance } from '@sennder/auth'

export const HttpClientToken = Symbol('HttpClient token')
export const HttpClientForProxyToken = Symbol('HttpClientForProxy token')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ApiResponse = any

export const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  paramsSerializer: (params: {}) => stringify(params),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  adapter: cacheAdapterEnhancer(axios.defaults.adapter!, {
    enabledByDefault: false,
    cacheFlag: 'useCache'
  })
})

export const httpClientForProxy = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  paramsSerializer: (params: {}) => stringify(params),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  adapter: cacheAdapterEnhancer(axios.defaults.adapter!, {
    enabledByDefault: false,
    cacheFlag: 'useCache'
  })
})

httpClient.interceptors.request.use(async (config) => {
  const userToken = Cookies.get(AuthenticationService.userTokenCookieName)
  const auth0Instance = getInstance()
  const isAuthenticated = await auth0Instance?.auth0Client?.isAuthenticated()
  if (userToken || isAuthenticated) {
    config.headers.Authorization = await getAuthorizationHeaderWithParams(
      process.env.VUE_APP_AUTH0_MOTHERSHIP_AUDIENCE || ''
    )
  }

  return config
})

httpClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error?.response?.status === 401) {
      bus.emit(AppEvent.REDIRECT_TO_LOGIN)
    }

    return Promise.reject(error)
  }
)

httpClientForProxy.interceptors.request.use(async (config) => {
  const userToken = Cookies.get(AuthenticationService.userTokenCookieName)
  const auth0Instance = getInstance()
  const isAuthenticated = await auth0Instance?.auth0Client?.isAuthenticated()
  if (userToken || isAuthenticated) {
    config.headers.Authorization = await getAuthorizationHeaderWithParams(
      process.env.VUE_APP_AUTH0_FRONTAPP_AUDIENCE || ''
    )
  }

  return config
})

httpClientForProxy.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error?.response?.status === 401) {
      bus.emit(AppEvent.REDIRECT_TO_LOGIN)
    }

    return Promise.reject(error)
  }
)
