import { useWindowSize } from '@vueuse/core';
import { computed } from '@vue/composition-api';

// Based on token in @/styles/base/tokens/_resolutions.scss
const phablet = 576;
const tablet = 768;
const laptop = 992;
const desktop = 1200;
const widescreen = 1440;

const mediaMin = (bp: number, windowWidth: number) => windowWidth >= bp;

const { width } = useWindowSize();

export default () => ({
  isPhablet: computed<boolean>(() => mediaMin(phablet, width.value)),
  isTablet: computed<boolean>(() => mediaMin(tablet, width.value)),
  isLaptop: computed<boolean>(() => mediaMin(laptop, width.value)),
  isDesktop: computed<boolean>(() => mediaMin(desktop, width.value)),
  isWideScreen: computed<boolean>(() => mediaMin(widescreen, width.value)),
});
