

























import { defineComponent } from '@vue/composition-api';
import { Button } from '@sennder/plankton';

export default defineComponent({
  name: 'ModalActions',
  components: { Button },
  props: {
    confirmLabel: { type: String, default: null },
    cancelLabel: { type: String, default: null },
    showConfirm: { type: Boolean, default: true },
    align: {
      type: String,
      default: 'right',
      validator: (val: string) => ['left', 'right', 'center'].includes(val),
    },
  },
});
