









import { defineComponent } from '@vue/composition-api';
import TransitionFade from '@/components/atoms/Transitions/TransitionFade.vue';

export default defineComponent({
  name: 'Veil',
  components: { TransitionFade },
});
