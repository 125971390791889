import isPlainObject from 'lodash/isPlainObject'
import isArray from 'lodash/isArray'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'

function transformCasingInObject(obj, transformationFn) {
  return Object.entries(obj).reduce((result, [key, value]) => {
    result[transformationFn(key)] = transformCasing(value, transformationFn)
    return result
  }, {})
}

function transformCasing(value, transformationFn) {
  if (isPlainObject(value)) return transformCasingInObject(value, transformationFn)
  if (isArray(value)) return transformCasingInArray(value, transformationFn)
  return value
}

function transformCasingInArray(array, transformationFn) {
  return array.map(v => transformCasing(v, transformationFn))
}

/** Converts the keys of an object to snake case */
export function transformKeysToSnakeCase<T>(camelCaseObject) {
  return transformCasing(camelCaseObject, snakeCase) as T
}

/** Converts the keys of an object to camel case */
export function transformKeysToCamelCase<T>(snakeCaseObject) {
  return transformCasing(snakeCaseObject, camelCase) as T
}
