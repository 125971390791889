import { inject } from '@vue/composition-api';
import {
  FeatureFlagService,
  FeatureFlagServiceToken,
} from '@/services/FeatureFlag/FeatureFlagService';

export default () => {
  const service = inject<FeatureFlagService>(FeatureFlagServiceToken);

  const isActive = (flag: string) => service?.isActive(flag) ?? false;

  return {
    isActive,
  };
};
