import Vue from 'vue';
import Vuelidate from 'vuelidate';
import CompositionApi from '@vue/composition-api';
import Vue2Transitions from 'vue2-transitions';
import { register } from 'timeago.js';
import vClickOutside from 'v-click-outside';
// import de from 'timeago.js/lib/lang/de';
import en from 'timeago.js/lib/lang/en_US';
import es from 'timeago.js/lib/lang/es';
import fr from 'timeago.js/lib/lang/fr';
import it from 'timeago.js/lib/lang/it';
// import lt from 'timeago.js/lib/lang/lt';
// import lv from 'timeago.js/lib/lang/lv';
// import nl from 'timeago.js/lib/lang/nl';
// import pl from 'timeago.js/lib/lang/pl';
// import pt from 'timeago.js/lib/lang/pt';
// import ru from 'timeago.js/lib/lang/ru';
// eslint-disable-next-line @typescript-eslint/camelcase
import { Locale } from './models/locales.model';

// register(Locale.DE, de);
register(Locale.EN, en);
register(Locale.ES, es);
register(Locale.FR, fr);
register(Locale.IT, it);
// register(Locale.LT, lt);
// register(Locale.LV, lv);
// register(Locale.NL, nl);
// register(Locale.PL, pl);
// register(Locale.PT, pt);
// register(Locale.RU, ru);

Vue.use(vClickOutside);
Vue.use(CompositionApi);
Vue.use(Vuelidate);
Vue.use(Vue2Transitions);
