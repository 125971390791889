

















































import { computed, defineComponent } from '@vue/composition-api'
import Veil from '@/components/atoms/Veil/Veil.vue'
import TransitionFade from '@/components/atoms/Transitions/TransitionFade.vue'
import TransitionPop from '@/components/atoms/Transitions/TransitionPop.vue'
import TransitionSlide from '@/components/atoms/Transitions/TransitionSlide.vue'
import SvgIcon from '@/components/atoms/SvgIcon/SvgIcon.vue'
import useBreakpoints from '@/hooks/useBreakpoints'

export default defineComponent({
  name: 'Modal',
  components: {
    Veil,
    SvgIcon,
    TransitionFade
  },
  props: {
    opened: { type: Boolean, default: false },
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    onConfirm: { type: Function, default: null },
    confirmLabel: { type: String, default: null }
  },
  setup() {
    const { isPhablet } = useBreakpoints()
    const modalTransitionComponent = computed(() =>
      isPhablet.value ? TransitionPop : TransitionSlide
    )

    return {
      modalTransitionComponent
    }
  }
})
