import Vue from 'vue'
import VueRouter from 'vue-router'
import container from '@/services/dependency-injection'
import { UserServiceToken } from '@/services/User/UserService'

import { buildAuthenticationGuard } from '@/router/route-guards/AuthenticationGuard'
import closeMobileNav from '@/router/route-guards/closeMobileNav'
import { AppEvent, bus } from '@/events'
import names from '@/router/routes/names'
import { AlertingServiceToken } from '@/services/Alerting/AlertingService'
import { AnalyticsServiceToken } from '@/services/Analytics/AnalyticsService'
import routes from './routes'

const { isNavigationFailure, NavigationFailureType } = VueRouter

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(buildAuthenticationGuard(container[UserServiceToken], container[AnalyticsServiceToken]))
router.beforeEach(closeMobileNav)
bus.on(AppEvent.REDIRECT_TO_LOGIN, async () => {
  router.push({ name: names.login }).catch((failure) => {
    // Avoid logging navigation cancelled error
    // More info : https://router.vuejs.org/guide/advanced/navigation-failures.html#detecting-navigation-failures
    if (isNavigationFailure(failure, NavigationFailureType.aborted)) return

    container[AlertingServiceToken].logError(failure)
  })
})

export default router
