import '@sennder/plankton/dist/plankton.css'
import './styles/_main.scss'
import './services/Alerting'
import './plugins'
import './directives'
import Vue from 'vue'
import segmentInit from '@/services/Analytics'
import provide, {
  featureFlagService,
  analyticsService
} from '@/services/dependency-injection'
import { isDevelopmentEnv } from '@/utils/environment-detection'
import {
  AnalyticsServiceToken,
  toggleEvents
} from '@/services/Analytics/AnalyticsService'
import { UserServiceToken } from '@/services/User/UserService'
import { tryToAuthenticateUser } from '@/router/route-guards/AuthenticationGuard'
import Auth0Plugin, { auth0Config } from '@sennder/auth'
import App from './App.vue'
import router from './router'
import i18n from './i18n/i18n'
import svgIcon from './config/webpack/svgIconContext'
import useFront from './hooks/useFront'
import events from './notifications/events'

svgIcon()

Vue.config.productionTip = false

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  ...auth0Config,
  clientId: process.env.VUE_APP_AUTH0_CLIENTID,
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  audience: process.env.VUE_APP_AUTH0_MOTHERSHIP_AUDIENCE,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
    )
  }
})

segmentInit(Vue, router)

if (isDevelopmentEnv) window.toggleEvents = toggleEvents

const main = async () => {
  analyticsService.trackEvent(events.APP_OPENED, null)
  await Promise.all([
    featureFlagService.init(),
    tryToAuthenticateUser(provide[UserServiceToken], provide[AnalyticsServiceToken])
  ])

  const { init } = useFront(router, provide[AnalyticsServiceToken])
  init()
  new Vue({
    provide,
    router,
    i18n,
    render: (h) => h(App)
  }).$mount('#app')
}

main()
