import VueSegmentAnalytics from 'vue-segment-analytics';

export default (Vue, router) => {
  if (process.env.VUE_APP_SEGMENT_TOKEN) {
    Vue.use(VueSegmentAnalytics, {
      id: process.env.VUE_APP_SEGMENT_TOKEN,
      router,
    });
  }
};
