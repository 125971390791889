import { Route } from 'vue-router'
import { user } from '@/hooks/useUser'
import Cookies from 'js-cookie'
import names from '@/router/routes/names'
import UserService from '@/services/User/UserService'
import AuthenticationService from '@/services/Authentication/AuthenticationService'
import AnalyticsService from '@/services/Analytics/AnalyticsService'
import { getInstance } from '@sennder/auth'
import { watchEffect } from '@vue/composition-api'

export async function tryToAuthenticateUser(
  userService: UserService,
  analyticsService: AnalyticsService
) {
  const auth0Instance = getInstance()
  const isAuth0Authenticated = await auth0Instance?.auth0Client?.isAuthenticated()
  const userToken = Cookies.get(AuthenticationService.userTokenCookieName)
  if (!user.value && (isAuth0Authenticated || userToken)) {
    try {
      user.value = await userService.getUser()
    } catch (err) {
      Cookies.remove(AuthenticationService.userTokenCookieName, {
        sameSite: 'None',
        secure: true
      })
    }
    if (user.value) {
      analyticsService.identifyUser(user.value)
    }
  }
}

export function buildAuthenticationGuard(
  userService: UserService,
  analyticsService: AnalyticsService
) {
  // eslint-disable-next-line consistent-return
  return async (to: Route, from: Route, next: Function) => {
    const auth0Instance = getInstance()

    const verify = async () => {
      await tryToAuthenticateUser(userService, analyticsService)
      const shouldRedirectUnauthUser = !to.meta?.public && !user.value

      if (shouldRedirectUnauthUser) {
        return next({ name: names.login, query: { next: to.path } })
      }

      const shouldRedirectAuthUser = to.meta?.public && user.value

      if (shouldRedirectAuthUser) {
        return next({ name: names.home })
      }

      return next()
    }

    if (!auth0Instance?.loading) {
      return verify()
    }
    const stop = watchEffect(() => {
      if (auth0Instance.loading === false) {
        stop()
        return verify()
      }
      return null
    })
  }
}
