import { computed, ref } from '@vue/composition-api';
import { TranslateResult } from 'vue-i18n';

type ConfirmWording = TranslateResult | string | null;

const title = ref<ConfirmWording>(null);
const subtitle = ref<ConfirmWording>(null);
const confirmLabel = ref<ConfirmWording>(null);
const cancelLabel = ref<ConfirmWording>(null);
const onConfirm = ref<Function | null>(null);

export interface ConfirmationState {
  title: ConfirmWording;
  subtitle: ConfirmWording;
  confirmLabel: ConfirmWording;
  cancelLabel: ConfirmWording;
  onConfirm: Function | null;
}

export default () => {
  const updateState = (req: Partial<ConfirmationState>) => {
    title.value = req.title ?? null;
    subtitle.value = req.subtitle ?? null;
    confirmLabel.value = req.confirmLabel ?? null;
    cancelLabel.value = req.cancelLabel ?? null;
    onConfirm.value = req.onConfirm ?? null;
  };

  return {
    title: computed(() => title.value),
    subtitle: computed(() => subtitle.value),
    confirmLabel: computed(() => confirmLabel.value),
    cancelLabel: computed(() => cancelLabel.value),
    onConfirm: computed(() => onConfirm.value),
    updateState,
  };
};
