import { captureException } from '@sentry/browser';

export const AlertingServiceToken = Symbol('AlertingService token');

export default class AlertingService {

  logError(e: Error) {
    captureException(e);
  }

}
