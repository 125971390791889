










import { defineComponent } from '@vue/composition-api';

const tagValidator = ['h1', 'h2', 'h3'];

export default defineComponent({
  name: 'Title',
  props: {
    tag: {
      type: String,
      default: 'h1',
      validator: (prop: string) => tagValidator.includes(prop),
    },
  },
});
