import { ref, Ref } from '@vue/composition-api'

const isLoading: Ref<boolean> = ref(false)

export default function useLoader() {
  const setLoading = (loading: boolean): void => {
    isLoading.value = loading
  }

  return { isLoading, setLoading }
}
