import Vue from 'vue';
import { vTooltip, vPopover, vClosePopover } from '@sennder/plankton';

const Directives = {
  install() {
    vTooltip(Vue);
    vPopover(Vue);
    vClosePopover(Vue);
  },
};

Vue.use(Directives);
