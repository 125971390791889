import { User } from '@/models';
import { isDevelopmentEnv } from '@/utils/environment-detection';

export const AnalyticsServiceToken = Symbol('AnalyticsService token');

let isActive = false;

export const toggleEvents = (param = false) => isActive = param;

export default class AnalyticsService {

  trackEvent(eventName, eventBody) {
    if (isDevelopmentEnv && isActive) console.info(`[track] ${eventName} :`, eventBody);
    window.analytics?.track(eventName, eventBody);
  }

  identifyUser(user: User) {
    if (isDevelopmentEnv && isActive) console.info(`[identify] ${user.username}`);
    window.analytics?.identify(
      user.id,
      {
        name: user.full_name_without_title,
        email: user.username,
      },
    );
  }

}
