












import { defineComponent } from '@vue/composition-api'
import useI18n from '@/hooks/useI18n'
import SvgIcon from '@/components/atoms/SvgIcon/SvgIcon.vue'
import useUser from '@/hooks/useUser'

export default defineComponent({
  name: 'MobileNav',
  components: {
    SvgIcon
  },

  setup(props, { root: { $router, $i18n } }) {
    const { lang } = useI18n($i18n)
    const { logout } = useUser($router)
    const redirectToLogout = () => {
      window.open(`${process.env.VUE_APP_OCTOPUS_URL}/logout'`)
    }
    return {
      lang,
      logout,
      redirectToLogout
    }
  }
})
