import { ref } from '@vue/composition-api';

const opened = ref<boolean>(false);

export default () => {
  const toggle = () => opened.value = !opened.value;
  const close = () => opened.value = false;

  return {
    opened,
    toggle,
    close,
  };
};
