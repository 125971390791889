import { ref, Ref } from '@vue/composition-api'

import names from '@/router/routes/names'
import { OrderServiceToken } from '@/services/OrderService/OrderService'
import { OrderOverview } from '@/models/order.model'
import container from '@/services/dependency-injection'

export interface OrderReferenceResponse {
  orders: OrderOverview[]
  reference: string
}

const orders = ref<OrderReferenceResponse[]>([])
const service = container[OrderServiceToken]
const currentIndex = ref(0)
const isLoading = ref(false)
let expirationDate: Date | null = null
export default ($router) => {
  const noResults: Ref<boolean> = ref(false)

  const computeReferences = async (orderIds: string[], redirect = false) => {
    currentIndex.value += 1
    const localIndex = currentIndex.value

    if (orderIds.length) {
      try {
        const useCache = expirationDate && expirationDate > new Date()

        const ordersResponse = await service?.getOrders(orderIds, useCache || false)
        // if we didn't use the cache we update the new expiration date for the next requests to the current date + 5 minutes
        if (!useCache) {
          const newExpirationDate = new Date()
          newExpirationDate.setMinutes(newExpirationDate.getMinutes() + 5)
          expirationDate = newExpirationDate
        }

        // check that we don't have another pending operation that should overides the old ones
        if (localIndex === currentIndex.value) {
          if (
            ordersResponse.length &&
            ordersResponse.some((item) => item.orders.length > 0)
          ) {
            orders.value = ordersResponse.filter((item) => item.orders.length > 0)
            noResults.value = false
            if (redirect) {
              $router.push({ name: names.orderDetails })
            }
          } else {
            noResults.value = true
            orders.value = []
            if (redirect) {
              if ($router.currentRoute.name !== names.searchOrders) {
                orders.value = []
                $router.push({ name: names.searchOrders })
              }
            }
          }
        }
      } catch (err) {
        //
      }
    } else if (localIndex === currentIndex.value) {
      orders.value = []
      if (redirect && $router.currentRoute.name !== names.searchOrders) {
        $router.push({ name: names.searchOrders })
      }
    }
  }

  return {
    computeReferences,
    orders,
    noResults,
    isLoading
  }
}
