import Front from '@frontapp/plugin-sdk'
import { Observable } from 'rxjs'
import useOrderDetails from '@/hooks/useOrderDetailRedirect'
import { getOrderIdsFromText } from '@/utils/orderFunctions'
import { httpClientForProxy } from '@/services/httpClient'
import useLoader from '@/hooks/useLoader'
import useUser from '@/hooks/useUser'
import { FrontService } from '@/services/FrontService/FrontService'
import events from '@/notifications/events'
import AnalyticsService from '@/services/Analytics/AnalyticsService'
import { Ref, ref } from '@vue/composition-api'
import names from '@/router/routes/names'
import { onlyUnique } from '@/utils/arrayFunctions'

const pendingConversationId: Ref<string | null> = ref(null)
export default ($router, analyticsService?: AnalyticsService) => {
  const { computeReferences } = useOrderDetails($router)
  const { setLoading } = useLoader()
  const frontService = new FrontService(httpClientForProxy)
  const { isAuthenticated } = useUser($router)

  const init = () => {
    if (process.env.VUE_APP_MOCK_FRONTAPP === 'true') {
      mockFront()
    }
    Front.contextUpdates.subscribe(async (context) => {
      switch (context.type) {
        case 'noConversation':
          break

        case 'singleConversation': {
          if (!isAuthenticated.value) {
            pendingConversationId.value = (context as any).conversation?.id
          } else {
            handleNewMessageEvent((context as any).conversation?.id)
          }
          break
        }

        case 'multiConversations':
          break

        default:
          break
      }
    })
  }

  const handleNewMessageEvent = async (messageId) => {
    setLoading(true)

    try {
      const response = await frontService.getMessagesFromConversation(messageId)
      if (response) {
        const ordersId = getOrderIdsFromText(
          response.map((t) => `${t.text} ${t.subject}`).join(' ')
        )?.filter(onlyUnique)
        analyticsService?.trackEvent(events.SEARCH_REFERENCE_RECOGNITION_SUBMIT, {
          ordersId,
          referencesCount: ordersId?.length
        })
        await computeReferences(ordersId || [], true)
      }
    } catch (error) {
      if (error.response?.status === 403) {
        $router.push({ name: names.userNotEnabledApi })
      }
    } finally {
      setLoading(false)
    }
  }

  const handlePendingMessage = () => {
    handleNewMessageEvent(pendingConversationId.value)
  }

  const mockFront = () => {
    Front.contextUpdates = new Observable((observer) => {
      window.addEventListener('front-event', ((event: CustomEvent) => {
        frontService.getMessagesFromConversation = () =>
          new Promise((resolve) => {
            resolve([
              {
                id: 'id',
                text: event.detail.conversation.text,
                subject: event.detail.conversation.subject
              }
            ])
          })
        observer.next(event.detail)
      }) as EventListener)
    })
  }
  return { handlePendingMessage, init, pendingConversationId, handleNewMessageEvent }
}
