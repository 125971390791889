import Cookies from 'js-cookie'
import { HttpClient } from '../httpClient.type'

export const AuthenticationServiceToken = Symbol('AuthenticationService token')

export default class AuthenticationService {

  static userTokenCookieName = 'auth_token_octopus'

  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async loginWithCredentials(credentials: { email: string; password: string }) {
    try {
      const {
        data: { key }
      } = await this.httpClient.post('/auth/login/', credentials)

      return Cookies.set(AuthenticationService.userTokenCookieName, `${key}`, {
        sameSite: 'None',
        secure: true
      })
    } catch (err) {
      Cookies.remove(AuthenticationService.userTokenCookieName, {
        sameSite: 'None',
        secure: true
      })
      return Promise.reject(err)
    }
  }

  async sendChangePasswordRequest(email: string) {
    try {
      return await this.httpClient.post('/auth/password/reset/', { email })
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async setNewPassword({
    token,
    uid,
    password,
    confirmPassword
  }: {
    token: string
    uid: string
    password: string
    confirmPassword: string
  }): Promise<void> {
    try {
      return await this.httpClient.post('/auth/password/reset/confirm/', {
        token,
        uid,
        new_password1: password,
        new_password2: confirmPassword
      })
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errorMessage = err.response.data.new_password2

        return Promise.reject(errorMessage)
      }
      return Promise.reject(err)
    }
  }

}
